<template>
  <div class="ele-body">
    <el-card shadow="never">
      <!-- 搜索表单 -->
      <el-form :model="table.where" label-width="77px" class="ele-form-search" @keyup.enter.native="$refs.table.reload()"
        @submit.native.prevent>
        <div class="ele-table-tool ele-table-tool-default">
          <el-button @click="showEdit = true" class="ele-btn-icon addbtn" size="small"
            v-if="permission.includes('sys:notice:add')">添加关于我们
          </el-button>
        </div>
      </el-form>
      <!-- 数据表格 -->
      <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)" highlight-current-row
        :stripe=true>
        <template slot-scope="{index}">
          <el-table-column type="selection" width="45" align="center" fixed="left" />
          <el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left"
            show-overflow-tooltip />
          <el-table-column prop="side_name" label="显示版" show-overflow-tooltip min-width="250">
            <template slot-scope="{row}">
              {{ row.side_type == 1 ? '可蚁点用户版' : (row.side_type == 2 ? '可蚁点司机版' :
                (row.side_type == 3 ? '可蚁点跑腿版' : (row.side_type == 4 ? '可蚁点商家版' : '暂无对应版本'
                ))) }}
            </template>
          </el-table-column>
          <el-table-column label="更新时间" show-overflow-tooltip min-width="160">
            <template slot-scope="{row}">{{ row.create_time * 1000 | toDateString }}</template>
          </el-table-column>
          <el-table-column label="操作" width="200px" align="center" :resizable="false" fixed="right">
            <template slot-scope="{row}">
              <el-link @click="edit(row)" icon="el-icon-edit" type="primary" :underline="false"
                v-if="permission.includes('sys:notice:edit')">编辑</el-link>
            </template>
          </el-table-column>
        </template>
      </ele-data-table>
    </el-card>
    <!-- 编辑弹窗 -->
    <el-dialog v-dialogDrag :title="editForm.id ? '修改关于我们' : '添加关于我们'" :visible.sync="showEdit" @closed="editForm = {}"
      :destroy-on-close="true" custom-class="ele-dialog-form" :lock-scroll="false">
      <el-card shadow="never">
        <el-form :model="editForm" ref="editForm" :rules="editRules" label-width="82px">
          <el-row :gutter="15">
            <el-col :sm="12">
              <el-form-item label="显示版:">
                <el-select v-model="editForm.side_type" placeholder="请选择" clearable class="ele-fluid"
                  :disabled="editForm.id ? true : false">
                  <el-option label="可蚁点用户版" :value="1" />
                  <el-option label="可蚁点司机版" :value="2" />
                  <el-option label="可蚁点跑腿版" :value="3" />
                  <el-option label="可蚁点商家版" :value="4" />
                </el-select>
              </el-form-item>
            </el-col>

            <el-form-item label="请选择地区：" prop="pid">
              <el-select v-model="editForm.pid" @change="handleChangeProv1(editForm.pid)" placeholder='请选择省'
                class="selectStyle mr-10 mb-20" clearable>
                <el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
                  :label="option.name"></el-option>
              </el-select>
              <el-select v-model="editForm.cname" @change="handleChangeCity1(editForm.cname)" placeholder="请选择市"
                class="selectStyle mr-10 mb-20" clearable>
                <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
                  :label="option.name"></el-option>
              </el-select>
              <el-select v-model="editForm.aname" @change="$forceUpdate(), handleDis(editForm.aname)" placeholder="请选择区/县"
                class="selectStyle mr-10 mb-20" clearable>
                <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
                  :label="option.name"></el-option>
              </el-select>
            </el-form-item>





          </el-row>
          <!-- 富文本编辑器 -->
          <tinymce-editor v-model="editForm.content" :init="editContent" />
        </el-form>
      </el-card>
      <div slot="footer">
        <el-button @click="showEdit = false">取消</el-button>
        <el-button type="primary" @click="save">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TinymceEditor from '@/components/TinymceEditor'
import { mapGetters } from "vuex";
export default {
  name: "SysNotice",
  components: { TinymceEditor },
  data() {
    return {
      pid: '',
      cid: '',
      aid: '',
      provArr: [],
      cityArr: [],
      districtArr: [],
      table: { url: '/notice/about_index', where: {} },  // 表格配置
      choose: [],  // 表格选中数据
      showEdit: false,  // 是否显示表单弹窗
      editType: '',
      editForm: { source: 1, status: 1, is_top: 2 },  // 表单数据
      editRules: {  // 表单验证规则
        title: [
          { required: true, message: '请输入通知标题', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '请输入选择通知状态', trigger: 'blur' }
        ],
        source: [
          { required: true, message: '请输入选择通知来源', trigger: 'blur' }
        ],
        is_top: [
          { required: true, message: '请输入选择是否置顶', trigger: 'blur' }
        ],
      },
      // 自定义文件上传(这里使用把选择的文件转成blob演示)
      file_picker_callback: (callback, value, meta) => {
        let input = document.createElement('input');
        input.setAttribute('type', 'file');
        // 设定文件可选类型
        if (meta.filetype === 'image') {
          input.setAttribute('accept', 'image/*');
        } else if (meta.filetype === 'media') {
          input.setAttribute('accept', 'video/*');
        }
        input.onchange = () => {
          let file = input.files[0];
          let reader = new FileReader();
          reader.onload = (e) => {
            let blob = new Blob([e.target.result], { type: file.type });
            callback(URL.createObjectURL(blob));
          };
          reader.readAsArrayBuffer(file);
        }
        input.click();
      }
    }
  },
  computed: {
    ...mapGetters(["permission"]),
    editContent() {
      return {
        menubar: false,
        // file_picker_callback: this.file_picker_callback,
        skin_url: this.$store.state.theme.theme === 'dark' ? '/tinymce/skins/ui/oxide-dark' : '/tinymce/skins/ui/oxide',
        content_css: this.$store.state.theme.theme === 'dark' ? '/tinymce/skins/content/dark/content.css' : '/tinymce/skins/content/default/content.css'
      };
    }
  },
  mounted() {
    this.$http.get('/common/province_list').then(res => {
      let data = JSON.parse(res.data)
      this.provArr = data
    })
  },
  methods: {

    edit(row) {
      this.$http.post('/notice/info', { id: row.id }).then(res => {
        if (res.data.code === 0) {
          this.editForm = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(e => {
        this.$message.error(e.message);
      });
      this.showEdit = true;
    },
    /* 保存编辑 */
    save() {
      this.editForm.notice_type = 2
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          const loading = this.$loading({ lock: true });
          this.$http.post('/notice/edit', this.editForm).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.showEdit = false;
              this.$message({ type: 'success', message: res.data.msg });
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        } else {
          return false;
        }
      });
    },
    /**
 *修改省
 **/
    handleChangeProv1(e) {
      /** 获取被选省份的pid**/
      if (e) {
        let pid = ''
        let that = this
        this.changePro = true
        this.provArr.forEach(function (item) {
          if (item.pid == e) {
            pid = item.pid
          }
        })
        /** 根据被选省份的pid获取省市下面的市**/
        this.$http.post('/common/city_list', {
          pid: pid
        }).then(res => {
          let data = JSON.parse(res.data)
          that.cityArr = data
          /** 选择省份清空市县**/
          this.editForm.cid = ''
          this.editForm.aid = ''
          this.editForm.cname = ''
          this.editForm.aname = ''
        })
      } else {
        this.editForm.pid = ''
        this.editForm.cid = ''
        this.editForm.aid = ''
        this.editForm.pname = ''
        this.editForm.cname = ''
        this.editForm.aname = ''
      }

    },
    /**
     *修改市
     **/
    handleChangeCity1(e) {
      if (e) {
        /** 获取被选市的cid**/
        let cid = ''
        this.changeCity = true
        this.cityArr.forEach(function (item) {
          if (item.cid == e) {
            cid = item.cid
          }
        })
        /** 根据被选市的cid获取市下面的县**/
        this.$http.post('/common/area_list', {
          cid: cid
        }).then(res => {
          let data = JSON.parse(res.data)
          this.districtArr = data
          /** 选择省份清空县**/
          this.editForm.aid = ''
          this.editForm.aname = ''
        })
      } else {
        // this.editForm.cname = ''
        this.editForm.aid = ''
        this.editForm.aname = ''
      }
    },
    /* 删除 */
    remove(row) {
      if (!row) {  // 批量删除
        if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
        let ids = this.choose.map(d => d.id);
        this.$confirm('确定要删除选中的通知吗?', '提示', { type: 'warning' }).then(() => {
          const loading = this.$loading({ lock: true });
          this.$http.post('/notice/delete', { id: ids }).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.$message({ type: 'success', message: res.data.msg });
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        }).catch(() => 0);
      } else {  // 单个删除
        const loading = this.$loading({ lock: true });
        this.$http.post('/notice/delete', { id: row.id }).then(res => {
          loading.close();
          if (res.data.code === 0) {
            this.$message({ type: 'success', message: res.data.msg });
            this.$refs.table.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(e => {
          loading.close();
          this.$message.error(e.message);
        });
      }
    },

  }
}
</script>

<style scoped>
.ele-block>>>.el-upload,
.ele-block>>>.el-upload-dragger {
  width: 100%;
}
</style>